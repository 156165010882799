// extracted by mini-css-extract-plugin
export var description = "styles-module--description--zlcWv";
export var order = "styles-module--order---FJrg";
export var orderDate = "styles-module--orderDate--QQISZ";
export var orderId = "styles-module--orderId--VXyY+";
export var orderLink = "styles-module--orderLink--4m4tt";
export var selectedTab = "styles-module--selectedTab--RQf3p";
export var tab = "styles-module--tab--l8bSE";
export var tabs = "styles-module--tabs--ceyJT";
export var tag = "styles-module--tag--4mC8P";
export var tags = "styles-module--tags--ogxW5";
export var total = "styles-module--total--LPGKd";