import React, {useState, useEffect} from 'react';
import {get} from '~utils/api';
import {OrderTabTypes} from '~utils/enum';
import {generateUrlParameters} from '~utils/helper';
import Loading from '~components/page-loader';
import Order from './order';
import * as styles from './styles.module.css';

const orderTabs = [
  {
    title: 'Chưa thanh toán',
    key: OrderTabTypes.NotPaid,
  },
  {
    title: 'Đang giao',
    key: OrderTabTypes.Delivering,
  },
  {
    title: 'Đã hoàn thành',
    key: OrderTabTypes.Done,
  },
  {
    title: 'Đã hủy',
    key: OrderTabTypes.Cancel,
  },
];

const defaultParameters = {
  'pagination.page': 1,
  'pagination.limit': 10,
  consumer_order_tab_type: OrderTabTypes.NotPaid,
  'sort.prop_name': 'CreatedDate',
  'sort.direction': 'Desc',
};

const AccountOrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [params, setParams] = useState(defaultParameters);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const getList = async parameters => {
    try {
      setLoading(true);
      const res = await get(`/v1/order/list${parameters ? `?${generateUrlParameters(parameters)}` : ''}`);
      if (!res) return;
      setOrders(res.items || []);
      setTotal(res.total);
    } finally {
      setLoading(false);
      firstLoading && setFirstLoading(false);
    }
  };

  useEffect(() => {
    getList(params);
  }, [params]);

  return (
    <div id='account-profile'>
      <div className={styles.tabs}>
        {orderTabs.map(x => (
          <div
            className={`${styles.tab} ${params.consumer_order_tab_type === x.key ? styles.selectedTab : ''}`}
            key={x.key}
            onClick={() => setParams({...params, consumer_order_tab_type: x.key})}
          >
            {x.title}
          </div>
        ))}
      </div>
      <div className='pos-relative'>
        {!firstLoading && <Loading loading={loading} />}
        {!loading &&
          orders.map((x, i) => {
            return (
              <React.Fragment key={x.orderId}>
                {i > 0 && <hr className='my-20 bg-gray-semi' />}
                <Order key={x.orderId} order={x} />
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default AccountOrderHistory;
