import React from 'react';
import {navigate} from 'gatsby';
import {formatDate, toVND} from '~utils/helper';
import * as styles from './styles.module.css';

const Order = ({order}) => {
  return (
    <div onClick={() => navigate(`/account/order-history/${order.orderId}`)} className='cursor-pointer'>
      <div className={styles.order}>
        <div>
          <h4 className={styles.orderId}>#RENTZY{order.orderId}</h4>
          <p className={styles.orderDate}>({formatDate(order.createdDate, 'MMM DD, yyyy')})</p>
        </div>
        <p onClick={() => navigate(`/account/order-history/${order.orderId}`)} className={styles.orderLink}>
          Xem chi tiết
        </p>
      </div>
      <div className={styles.order}>
        <div className={styles.tags}>
          <span className={styles.tag}>{order.quantities} sản phẩm</span>
        </div>
        <p className={styles.total}>Tổng tiền: {toVND(order.totalPrice)}</p>
      </div>
    </div>
  );
};

export default Order;
